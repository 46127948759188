<template>
  <div class="bg-neutral-50">
    <div
      v-if="isLoading"
      class="p-10 bg-neutral-50"
    >
      <brightbid-loader
        class="mt-64"
        size="100"
      />
    </div>
    <div
      v-else
      class="main-container base-scrollbar"
    >
      <div class="mb-20 w-full">
        <div class="mx-4 p-8 bg-white mt-4 rounded-xl">
          <p class="h4 mb-8">Alerts</p>
          <div class="flex gap-4 flex-col items-center">
            <div
              v-for="notification in notifications"
              :key="notification.id"
              class="w-full"
            >
              <banner-card
                v-if="isAnomalyTypeSupported(notification.anomaly_type)"
                :type="'alert'"
                @resolve="resolve(notification)"
              >
                <template #header>
                  <div v-if="notification.anomaly_type === 'account no data'">
                    <p class="h5">
                      <span class="font-extrabold">{{ notification.site_name }}</span> connection to Google Ads appears
                      inactive
                    </p>
                  </div>
                  <div v-if="notification.anomaly_type === 'campaign flat line'">
                    <p class="h5">
                      <span class="font-extrabold">{{ notification.site_name }}</span
                      >'s campaign <span class="font-extrabold">{{ notification.google_campaign_name }}</span> has
                      stopped running
                    </p>
                  </div>
                  <div v-if="notification.anomaly_type === 'metric out of range'">
                    <p class="h5">
                      Unusual value for metric <span>{{ getMetricDetails(notification.anomaly_details) }}</span> on
                      <span class="font-extrabold">{{ notification.site_name }}</span
                      >'s campaign <span class="font-extrabold">{{ notification.google_campaign_name }}</span>
                    </p>
                  </div>
                </template>
                <template #content>
                  <div v-if="notification.anomaly_type === 'account no data'">
                    <p class="p2">
                      The Google Ads account
                      <span class="font-extrabold"
                        >{{ notification.google_customer_name }} ({{
                          addDashesToGoogleAccountId(notification.google_customer_id)
                        }})</span
                      >
                      seems to have ceased activity. Please review Google Ads for potential error messages.
                    </p>
                  </div>
                  <div v-if="notification.anomaly_type === 'campaign flat line'">
                    <p class="p2">
                      This campaign has stopped receiving data. Check Google Ads account
                      <span class="font-extrabold"
                        >{{ notification.google_customer_name }} ({{
                          addDashesToGoogleAccountId(notification.google_customer_id)
                        }})</span
                      >
                      for any possible issues or error messages.
                    </p>
                  </div>
                  <div v-if="notification.anomaly_type === 'metric out of range'">
                    <p class="p2">
                      The <span>{{ getMetricDetails(notification.anomaly_details) }}</span> value is outside of the
                      usual range for this campaign. Check Google Ads account
                      <span class="font-extrabold"
                        >{{ notification.google_customer_name }} ({{
                          addDashesToGoogleAccountId(notification.google_customer_id)
                        }})</span
                      >
                      for any issues or recent changes.
                    </p>
                  </div>
                </template>
                <template #date>
                  <p class="p2 text-bb-text-secondary">
                    {{ notification.anomaly_day }}
                  </p>
                </template>
              </banner-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toast from '@/components/shared/Toast.vue'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'
import BannerCard from '@/components/card/BannerCard.vue'
import { addDashesToGoogleAccountId } from '@/core/store'
import { METRIC_LABEL_MAPPING } from '../../core/constants'

export default {
  name: 'PortfolioOverview',
  components: {
    BannerCard,
    BrightbidLoader,
  },
  data() {
    return {
      isLoading: false,
      isModalLoading: false,
      formValues: null,
      size: null,
      existingUser: null,
      notifications: [],
    }
  },
  async mounted() {
    await this.loadPortfolio()
  },
  methods: {
    addDashesToGoogleAccountId,
    async loadPortfolio() {
      this.isLoading = true
      try {
        this.notifications = (await axios.get(`/common/portfolio/organizations/notifications`)).data
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to fetch portfolio.',
            type: 'error',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async resolve(notification) {
      try {
        await axios.post(`search/site/${notification.site_id}/anomaly/${notification.id}/resolve`, {})
        await this.loadPortfolio()
      } catch {
        console.log('error')
      }
    },
    getMetricDetails(details) {
      const metric = details.split('=')[0].toLowerCase()
      return METRIC_LABEL_MAPPING[metric] || ''
    },
    isAnomalyTypeSupported(type) {
      return ['metric out of range', 'campaign flat line', 'account no data'].includes(type)
    },
  },
}
</script>
<style scoped lang="scss">
.main-container {
  overflow-y: auto;
  height: calc(100vh - 211px);
}
</style>
